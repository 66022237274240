.MuiTabs-indicator {
  left: 0;
  background-color: #6e06f2 !important;
}

.MuiTabs-flexContainer {
  border-left: 3px solid #4e525a;
  width: fit-content;
}

.MuiTabs-root {
  width: 280px !important;
}
